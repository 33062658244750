<script lang="ts" setup>
import type { ProductSummary } from "@/types";

const { blok } = defineProps<{ blok: ProductSummary }>();

const swiperRef = ref();

const onSwiper = (swiper: any) => {
  swiperRef.value = swiper;
};

const goToPrev = () => {
  const currentIndex = swiperRef?.value?.activeIndex || 0;
  if (!swiperRef?.value?.isBeginning) {
    swiperRef?.value?.slideTo(currentIndex - 1);
  }
};

const goToNext = () => {
  const currentIndex = swiperRef?.value?.activeIndex || 0;
  if (!swiperRef?.value?.isEnd) {
    swiperRef?.value?.slideTo(currentIndex + 1);
  }
};

const calculateWidth = (imageRatio: string) => {
  const ratio = imageRatio;

  if (!ratio) return 100;

  const [widthRatio, heightRatio] = ratio
    .split("x")
    .map((item) => parseInt(item, 10));

  const width = 240 * (widthRatio / heightRatio);
  return `${width}px`;
};
</script>

<template>
  <Container
    v-editable="blok"
    :style="{ backgroundColor: blok.backgroundColour || 'inherit' }"
    container-class="3xl:p-0 pb-5"
  >
    <div class="flex md:items-center items-start py-6 relative">
      <h2
        class="flex-1 font-helvetica-light text-white md:text-center text-[25px] lg:text-[33px] md:inline-flex md:justify-center"
      >
        {{ blok.heading }}
      </h2>
      <div
        v-if="blok.items.length > 1"
        class="flex gap-4 h-[34px] items-end md:h-auto md:absolute right-0 relative"
      >
        <IconArrowLeftSolid
          color="#fff"
          class="h-[25px] w-[25px] lg:h-11 lg:w-11 cursor-pointer"
          @click="goToPrev"
        />
        <IconArrowRightSolid
          color="#fff"
          class="h-[25px] w-[25px] lg:h-11 lg:w-11 cursor-pointer"
          @click="goToNext"
        />
      </div>
    </div>
    <template v-for="item in blok.items" :key="item._uid">
      <link rel="preload" as="image" :href="item.image.filename" />
      <link
        v-if="item?.mobileImage?.filename !== item.image.filename"
        rel="preload"
        as="image"
        :href="item?.mobileImage?.filename"
      />
    </template>
    <Swiper
      :space-between="10"
      :centered-slides="false"
      slides-per-view="auto"
      class="2xl:!-mr-20 lg:!-mr-10 sm:!mx-0 !-mx-2.5 3xl:!mx-0"
      :breakpoints="{
        768: {
          spaceBetween: 20,
          centeredSlides: blok.items.length > 1 ? false : true,
        },
      }"
      @swiper="onSwiper"
    >
      <SwiperSlide
        v-for="item in blok.items"
        :key="item._uid"
        v-editable="item"
        class="flex items-start !w-auto max-w-full"
      >
        <div class="flex flex-col gap-[26px] relative items-start">
          <Image
            :blok="{ image: item.image, ratio: item.ratio }"
            class="h-[240px] lg:h-[650px] max-w-full md:flex justify-end hidden"
          >
            <div v-if="item.product" class="p-2.5">
              <FavoriteButton :product-uuid="item.product" />
            </div>
          </Image>

          <!-- For mobile screen -->
          <Image
            :blok="{ image: item.mobileImage || item.image, ratio: '1x1' }"
            class="h-[240px] max-w-full md:hidden justify-end flex"
          >
            <div v-if="item.product" class="p-2.5">
              <FavoriteButton :product-uuid="item.product" />
            </div>
          </Image>
          <div class="text-white flex flex-col gap-[5px]">
            <BlokLink
              v-for="button in item.button"
              :key="button._uid"
              :blok="button.url"
              :is-form="button.isForm"
              class="md:flex gap-2.5 text-[18px] justify-between items-start lg:text-xl font-helvetica-bold lg:!w-[370px] hidden"
              :style="{ width: calculateWidth(item.ratio) }"
            >
              <span class="overflow-hidden whitespace-nowrap text-ellipsis">{{
                button.text
              }}</span>
              <NuxtImg
                width="24px"
                height="24px"
                src="/icon/chevron-right-rounded.svg"
                alt="Chevron right"
                class="invert hidden md:block"
              />
            </BlokLink>

            <!-- For mobile screen -->
            <BlokLink
              v-for="button in item.button"
              :key="button._uid"
              :blok="button.url"
              :is-form="button.isForm"
              class="md:hidden gap-2.5 items-center text-[18px] justify-between lg:text-xl font-helvetica-bold flex"
              :style="{ width: calculateWidth('1x1') }"
            >
              <span class="overflow-hidden whitespace-nowrap text-ellipsis">{{
                button.text
              }}</span>
            </BlokLink>
            <p
              class="hidden md:block lg:!w-[350px]"
              :style="{ width: calculateWidth(item.ratio) }"
            >
              {{ item.text }}
            </p>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
    <div
      v-if="blok.buttonText"
      class="mt-5 py-5 lg:py-10 flex justify-center items-center"
    >
      <BlokLink
        :blok="blok.buttonLink"
        class="bg-white rounded-full px-[49px] py-[19px]"
      >
        {{ blok.buttonText }}
      </BlokLink>
    </div>
  </Container>
</template>
